const initGA = () => {
  window['GoogleAnalyticsObject']='ga'
  window.ga=window.ga||function(){
    (window.ga.q=window.ga.q||[]).push(arguments)
  }
  window.ga.l=1*new Date();
  const firstScript = window.document.getElementsByTagName('script')[0]
  const script = window.document.createElement('script')
  script.src = 'https://www.google-analytics.com/analytics.js'
  script.async = 1
  firstScript.parentNode.insertBefore(script, firstScript)
  window.ga('create', 'UA-44197130-2', 'auto');
  window.ga('send', 'pageview');
}

initGA()
